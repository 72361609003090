import { graphql } from "gatsby"
import Image from "gatsby-image"
import React, { useState } from "react"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import CapabilityCard from "../../capabilities-card/capabilityCard"
import styles from "../../../styles/whatWeDo.module.scss"
import Layout from "../../Layout/Layout"
import WhiteSection from "../../whiteSection/whiteSection"
import tt from "../../../helpers/translation"
import buildResponsiveImages from "../../../helpers/buildResponsiveImages"
import NewSlider from "../../NewSlider/NewSlider"
import SliderBanner from "../../sliderBanner/SliderBanner"
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage"

const WhatWeDoPage = ({ data, lang, seoData, urls }) => {
  const logoReact = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-react") > -1
  ).childImageSharp.fluid
  const logoGatsby = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-gatsby") > -1
  ).childImageSharp.fluid
  const logoLaravel = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-laravel") > -1
  ).childImageSharp.fluid
  const logoWordPress = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-wordpress") > -1
  ).childImageSharp.fluid
  const logoSymfony = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-symfony") > -1
  ).childImageSharp.fluid
  const logoNode = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-node") > -1
  ).childImageSharp.fluid
  const logoAws = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-aws") > -1
  ).childImageSharp.fluid
  const logoVue = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-vue") > -1
  ).childImageSharp.fluid
  const logoDocker = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-docker") > -1
  ).childImageSharp.fluid
  const logoAzure = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-azure") > -1
  ).childImageSharp.fluid
  const logoDrupal = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-drupal") > -1
  ).childImageSharp.fluid
  const logoGoogleCloud = data.allFile.nodes.find(
    foto => foto.childImageSharp.fluid.src.indexOf("logo-google-cloud") > -1
  ).childImageSharp.fluid

  const whatWeDoImagesDesktop = data.whatWeDoImagesDesktop.nodes.map(
    node => node.childImageSharp
  )
  const whatWeDoImagesMobile = data.whatWeDoImagesMobile.nodes.map(
    node => node.childImageSharp
  )

  return (
    <Layout
      leftContactUs
      lan={lang}
      location="wedo"
      contrast={true}
      seoData={seoData}
      urls={urls}
    >
      <WhiteSection
        firstTitle={tt("Our services", lang)}
        subtitle={tt(
          "From the proposal, development, and implementation to maintenance and continuous improvement of innovative web projects with high added value for our clients.",
          lang
        )}
        first
      />
      <SliderBanner
        slides={buildResponsiveImages(
          whatWeDoImagesDesktop,
          whatWeDoImagesMobile
        ).map((item, index) => {
          return <ResponsiveImage key={index} alt="" fluid={item.fluid} />
        })}
      />
      {/* The next code is commented because it didn’t respond well when
      directly loading on the phone a certain route. Should be improved in order to use it instead of current solution
due to the current need to load js to display */}
      {/* <NewSlider
        slides={buildResponsiveImages(
          whatWeDoImagesDesktop,
          whatWeDoImagesMobile
        ).map((item, index) => {
          return <ResponsiveImage key={index} alt="" fluid={item.fluid} />
        })}
        className={styles.slider}
      ></NewSlider> */}
      <WhiteSection
        title={tt("What we do", lang)}
        subtitle={tt(
          "We deliver innovative software development services based on the natural cycle of digital projects.",
          lang
        )}
        text={
          <div className={styles.quote}>
            <div className={styles.description}>
              {tt(
                "Consulting, data, development, UX-UI, maintenance and improvement. Always working on behalf of the client and with an orientation to total success. Our greatest reward is the recurrence of our customers.",
                lang
              )}
            </div>
            {/* <p className={styles.description}>
              <strong>Marina Specht,</strong> CEO de MRM//McCann.{" "}
            </p> */}
          </div>
        }
      />

      <div className={`${styles.blue_section_container}`}>
        <div className={styles.text_container}>
          <h2>{tt("Our capabilities", lang)}</h2>
          <h3>{tt("Digital transformation", lang)}</h3>
          <p>
            {tt(
              "We accompany companies in the process of digital transformation. We analyze work processes and propose improvements based on appropriate technologies and on the training of people.",
              lang
            )}
          </p>
          <ul>
            <li>
              {tt(
                "Process analysis and preparation of the improvement proposal",
                lang
              )}
            </li>
            <li>
              {tt(
                "Development of digital projects customized to customer needs",
                lang
              )}
            </li>
            <li>{tt("Implementation of productivity tools", lang)}</li>
            <li>{tt("Training in the use of productivity tools", lang)}</li>
          </ul>
          <div className={styles.capabilities_container}>
            <CapabilityCard
              title={tt("Consultancy & Digital strategy", lang)}
              bullets={[
                tt("Analysis and technical evaluation", lang),
                tt("Competitive analysis", lang),
                tt("Digital Product Strategy", lang),
                tt("Architecture & solution design", lang),
              ]}
            />
            <CapabilityCard
              title={tt("Experience Design", lang)}
              bullets={[
                tt("UX/UI Design", lang),
                tt("Wireframing", lang),
                tt("Visual Mockup and Functional", lang),
                tt("Brand Design", lang),
              ]}
            />
            <CapabilityCard
              title={tt("Software web development", lang)}
              bullets={[
                tt("Project prototyping", lang),
                tt("Headless ecommerce development", lang),
                tt("Webapps development", lang),
                tt("Quality Assurance", lang),
              ]}
            />
            <CapabilityCard
              title={tt("Search Engine Optimization", lang)}
              bullets={[
                tt("Analysis and Keyword Research", lang),
                tt("SEO/SEM Plan", lang),
                tt("Implementation", lang),
                tt("Monitor Traffic & Rankings", lang),
              ]}
            />
          </div>
        </div>
      </div>
      <div className={styles.tech_solutions_container}>
        <h2 className={styles.solutions_title}>
          {tt("Technology Solutions", lang)}
        </h2>
        <div className={styles.tech_solutions_grid_container}>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoReact} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoNode} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoGatsby} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoVue} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoLaravel} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoSymfony} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoDocker} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoDrupal} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoWordPress} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoAws} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoGoogleCloud} />
          </div>
          <div className={styles.tech_logo_container}>
            <Image alt="" className={styles.image} fluid={logoAzure} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WhatWeDoPage
