import { Link } from "gatsby"
import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./index.module.scss"

const CapabilityCard = ({ title, bullets }) => {
  return (
    <div className={styles.wrapper}>
      <h3>{title}</h3>
      <ul>
        {bullets.map(bullet => (
          <li key={bullet}>{bullet}</li>
        ))}
      </ul>
    </div>
  )
}

CapabilityCard.propTypes = {
  bullets: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

export default CapabilityCard
