import { graphql } from "gatsby"
import React from "react"
import WhatWeDoPage from '../../components/pages/WhatWeDo'

const lang = "es"

const WhatWeDo = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_es,
    titleSeo: seoDataJson.title_es,
    description: seoDataJson.description_es,
    alternateLanguage: seoDataJson.alternateLanguage_es,
    alternateUrl: seoDataJson.alternateUrl_es,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "WHAT-WE-DO"
  })

  return (
    <WhatWeDoPage
      lang={lang}
      data={data}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const pageQuery = graphql`
  query whatWeDoEsQuery {
    allFile(filter: { ext: { regex: "/jpg|png/" } }) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    whatWeDoImagesDesktop: allFile(
      filter: {
        relativePath: { glob: "group/what_we_do/desktop/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    whatWeDoImagesMobile: allFile(
      filter: {
        relativePath: { glob: "group/what_we_do/mobile/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seoDataJson(name: { eq: "what-we-do" }) {
      url_es
      title_es
      description_es
      alternateLanguage_es
      alternateUrl_es
    }
  }
`

export default WhatWeDo
